import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose, mapProps } from '@shakacode/recompose';
import { useQuery } from '~/lazy_apollo/client';
import { Button, Typography } from '@popmenu/common-ui';

import { closeEditMenuItemCartLocationModal } from '../../../../shared/DishActions';
import { makeStyles } from '../../../../utils/withStyles';
import styles from './styles';
import { setMenuItemCartLocationId } from '../../../../shared/MenuItemCartActions';
import { withRestaurant } from '../../../../utils/withRestaurant';
import { getOrderingPageUrl } from '../MenuItemCartHelpers';
import locationsQuery from '../../../../libs/gql/queries/locations/restaurantWithLocationsQuery.gql';

import BasicModal from '../../../../admin/shared/BasicModal';

const useStyles = makeStyles(styles);

const EditMenuItemCartLocationModal = ({ historyPush, restaurant }) => {
  const classes = useStyles();
  const showEditMenuItemCartLocationModal = useSelector(state => state.dishes.showEditMenuItemCartLocationModal);
  const newLocationId = useSelector(state => state.dishes.locationId);
  const currentLocationId = useSelector(state => state.menuItemCart.menuItemCartLocationId);
  const cartType = useSelector(state => state.menuItemCart.menuItemCartType);
  const dispatch = useDispatch();

  const { data: locationsData, loading: locationsLoading } = useQuery(
    locationsQuery,
    {
      skip: !showEditMenuItemCartLocationModal,
      variables: {
        restaurantId: restaurant.id,
      },
    },
  );
  if (locationsLoading || !locationsData || !locationsData.restaurant || !locationsData.restaurant.locations) {
    return null;
  }

  if (
    !showEditMenuItemCartLocationModal ||
    !currentLocationId ||
    (currentLocationId && !locationsData.restaurant.locations.find(l => l.id === currentLocationId)) ||
    !newLocationId ||
    (newLocationId && !locationsData.restaurant.locations.find(l => l.id === newLocationId))
  ) {
    return null;
  }

  const orderingPageUrl = getOrderingPageUrl({ cartType, restaurant });
  return (
    <BasicModal
      actions={(
        <div style={{ margin: '0px 8px 8px 8px', width: '100%' }}>
          <Button
            className={classes.changeLocationButton}
            onClick={(e) => {
              e.stopPropagation();
              historyPush(`${orderingPageUrl}#choose-location`);
              dispatch(closeEditMenuItemCartLocationModal());
            }}
            size="small"
            variant="text"
          >
            <FormattedMessage id="consumer.ordering.change_locations" defaultMessage="See All Locations" />
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              dispatch(setMenuItemCartLocationId(newLocationId));
              dispatch(closeEditMenuItemCartLocationModal());
              historyPush(`${orderingPageUrl}#menu`);
            }}
            style={{ float: 'right' }}
          >
            <FormattedMessage id="consumer.ordering.swap_location" defaultMessage="Swap Location" />
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              dispatch(closeEditMenuItemCartLocationModal());
            }}
            style={{ float: 'right' }}
            variant="outlined"
          >
            <FormattedMessage id="consumer.ordering.keep_current_location" defaultMessage="Keep Current Location" />
          </Button>
        </div>
      )}
      className={classes.editModal}
      closeModal={() => dispatch(closeEditMenuItemCartLocationModal())}
      PaperProps={{
        'aria-label': 'Change Location',
      }}
      size="md"
      show={showEditMenuItemCartLocationModal}
      title={<FormattedMessage id="consumer.ordering.change_location_title" defaultMessage="Change Location" />}
    >
      <Typography>
        <FormattedMessage
          id="consume.ordering.current_location"
          defaultMessage="You're currently on the {locationName} location. "
          values={{
            locationName: locationsData.restaurant.locations.find(l => l.id === currentLocationId).name,
          }}
        />
        <FormattedMessage
          id="consumer.ordering.change_location_confirm_message"
          defaultMessage="The <b>{locationName}</b> location is the closest to you. Do you want to swap to this location? Selecting a different location will clear out your current bag."
          values={{
            b: chunks => <b>{chunks}</b>,
            locationName: locationsData.restaurant.locations.find(l => l.id === newLocationId).name,
          }}
        />
      </Typography>
    </BasicModal>
  );
};

EditMenuItemCartLocationModal.propTypes = {
  historyPush: PropTypes.func.isRequired,
  restaurant: PropTypes.shape({
    customPages: PropTypes.arrayOf(PropTypes.shape({
      hasCateringSection: PropTypes.bool,
      hasOnlineOrderingSection: PropTypes.bool,
      isEnabled: PropTypes.bool,
    })),
    id: PropTypes.number,
  }).isRequired,
};

export default compose(
  withRouter,
  mapProps(({ history, location, match, ...props }) => ({
    ...props,
    historyPush: history.push,
  })),
  withRestaurant,
)(EditMenuItemCartLocationModal);
